<template>
    <v-app>
        <div class="wrapper">
            <Main/>
            <Footer/>
        </div>
    </v-app>
</template>

<script>
import Footer from './components/Footer.vue'
import Main from './components/Main.vue'

export default {
  name: 'App',
  components: {
    Main,
    Footer
  }
}
</script>

<style lang="scss">
@mixin font($font_name, $file_name, $weight, $style) {
    @font-face {
        font-family: $font_name;
        font-display: swap;
        src: url("./assets/fonts/#{$file_name}.woff") format("woff"), url("./assets/fonts/#{$file_name}.woff2") format("woff2");
        font-weight: #{$weight};
        font-style: #{$style};
    }
}

@import "./assets/styles/_reset";

//!!...........ПЕРЕМЕННЫЕ.....................!!\\
$mainColor: #ffffff;
$secondColor: #000000;
$mainFF: "Montserrat";
//!...........................................!!\\

html{
    font-size: 10px;
}
body{
    font-family: $mainFF;
    font-weight: 400;
    font-size: 1.4rem;
    color: $secondColor;
}

body.lock{
    overflow: hidden;
}

.wrapper {
    min-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-width: 0;
}
.container {
    max-width: 93rem;
    padding: 0 1.5rem;
    margin: 0 auto;
    background-color: $mainColor;

}
.container-footer{
    max-width: 93rem;
    padding: 0 1.5rem;
    margin: 0 auto;
}
.main {
    flex: 1 1 auto;

}
@import "./assets/styles/_fonts";
@import "./assets/styles/_btn";
@import "./assets/styles/_main";
@import "./assets/styles/_hello";
@import "./assets/styles/_input";
@import "./assets/styles/_form";
@import "./assets/styles/_valid";
@import "./assets/styles/_footer";
</style>