<template>
    <main class="main">
    <div class="background-left"></div>
    <div class="background-right"></div>
    <div class="container">
        <div class="hello">
            <h2 class="hello__title"><span>Здравствуйте!</span></h2>
            <div class="hello__text"><span>
                Вы находитесь на странице оплаты школы Кэнбу Кокоро. Пожалуйста, заполните таблицу ниже
                    и следуйте инструкциям платежной системы при оплате
            </span></div>
        </div>
        <div class="form">
            <form class="form__form" id="form" @submit.prevent="sendData">
                <div class="form__title">ФИО ученицы</div>
                <div class="form__subtitle">Введите полностью ФИО занимающегося</div>
                <input class="form__fio-input form_input" type="text" v-model="name" placeholder=" " required>
                <div class="form__contacts">
                    <div class="form__contacts-phone">
                        <div class="form__title">Телефон</div>
                        <input class="form__phone-input form_input" type="text" v-model="phone" placeholder=" " required >
                    </div>
                    <div class="form__contacts-email">
                        <div class="form__title">E-mail</div>
                        <input class="form__mail-input form_input" type="email" v-model="email" placeholder=" " required>
                        <span class="form__error">В формате: example@site.com</span>
                    </div>
                </div>
                <div class="form__type">
                    <div class="form__title"><span>Выберите для оплаты</span></div>
                    <select class="form__type-input form_input has_arrow" name="type" v-model="selected_type" required>
                        <option :value="null" disabled hidden>Тип услуги</option>
                        <option v-for="option in options" :value="option" v-bind:key="option.name">{{option.name}}</option>
                    </select>
                </div>
                <div class="form__group">
                    <div class="form__subtitle group-subtitle"><span>{{selected_type.description}}</span>
                    </div>
                    <select class="form__group-input form_input has_arrow" name="group" v-model="selected_product" required>
                        <option :value="null" disabled hidden>Выберете вашу группу</option>
                        <option v-for="product in filteredProducts" :value="product" v-bind:key="product.name">{{product.name}}</option>
                    </select>
                </div>
                <div class="form__pay" v-if="selected_product">
                    <div class="form__pay-lessons">
                        <div class="form__title">{{selected_type.price_tip}}</div>
                        <select class="form__lesson-input form_input has_arrow" name="lessons" v-model="selected_price" required>
                            <option
                                v-for="price in selected_product.prices"
                                :value="price"
                                v-bind:key="price.name"
                            >
                                {{price.name}}
                            </option>
                        </select>
                    </div>
                    <div class="form__pay-count" v-if="selected_price.counter">
                        <div class="form__title">{{selected_type.counter_tip}}</div>
                        <div class="form__count-item form_input">
                            <button :disabled="selected_count <= 1" type="button" class="form__count-button" data-direction="minus" v-on:click="selected_count -= 1"><picture><source srcset="@/assets/img/arrow_left.webp" type="image/webp"><img src="@/assets/img/arrow_left.png" alt=""></picture></button>
                            <input class="form__count-input" type="text" v-model="selected_count" autocomplete="off">
                            <button type="button" class="form__count-button" data-direction="plus" v-on:click="selected_count += 1"><picture><source srcset="@/assets/img/arrow_right.webp" type="image/webp"><img src="@/assets/img/arrow_right.png" alt=""></picture></button>
                        </div>
                    </div>
                </div>
                <div class="form__month" v-if="selected_type.value === 'subscription'">
                    <div class="form__title">Оплата за месяц</div>
                    <select class="browser-default form__month-input form_input has_arrow" name="month" v-model="options.month" required>
                        <option
                            v-for="month in months"
                            :value="month.value"
                            v-bind:key="month.name"
                        >
                            {{month.name}}
                        </option>
                    </select>
                </div>
                <div class="form__sum">
                    Сумма к оплате:<span class="form_sum-value">{{totalCost}}р</span>
                </div>
                <div class="form__agreement">
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox" type="checkbox" name="personal_info" value="yes" required></label>
                        <span class="form__agreement-argument">Я даю свое <a target="_blank" href="https://payment.kenbu-kokoro.ru/assets/static/agreement.pdf">согласие на обработку персональных данных</a></span>
                    </div>
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox " type="checkbox" name="offer_agreement" value="yes" required></label>
                        <span class="form__agreement-argument">Я принимаю <a target="_blank" href="#">договор-оферту</a></span>
                    </div>
                    <div class="form__agreement-item">
                        <label><input class="agreement-checkbox" type="checkbox" name="privacy_policy" value="yes" required></label>
                        <span class="form__agreement-argument">Я ознакомлен с <a target="_blank" href="https://payment.kenbu-kokoro.ru/assets/static/policy.pdf">Политикой конфиденциальности</a></span>
                    </div>
                </div>
                <button class="form_button-pay" type="submit" name="paybtn" >Оплатить</button>
            </form>
        </div>
    </div>
    </main>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Main',
    data: () => ({
        options: [
            {
              value: "subscription",
              name: "Оплата занятий",
              description: "Выберите из списка группу, в которой вы занимаетесь: по метро и началу занятий в будний день.",
              price_tip: "Выберите тип абонемента",
              counter_tip: "Количество разовых занятий"
            },
            {
              value: "service",
              name: "Семинары",
              description: "Выберите тип",
              price_tip: "Укажите кол-во, если требуется",
              counter_tip: "Укажите кол-во"
            },
            {
              value: "product",
              name: "Заказ формы",
              description: "Выберите наименование",
              price_tip: "Укажите кол-во, если требуется",
              counter_tip: "Укажите кол-во"
            },
        ],
        products: [],
        filteredProducts: [],
        months: [
            {value: 0, name:'Январь'},
            {value: 1, name:'Февраль'},
            {value: 2, name:'Март'},
            {value: 3, name:'Апрель'},
            {value: 4, name:'Май'},
            {value: 5, name:'Июнь'},
            {value: 6, name:'Июль'},
            {value: 7, name:'Август'},
            {value: 8, name:'Сентябрь'},
            {value: 9, name:'Октябрь'},
            {value: 10, name:'Ноябрь'},
            {value: 11, name:'Декабрь'}
        ],
        selected_type: {},
        selected_product: {},
        selected_price: {},
        selected_count: 1,
        name: "",
        email: "",
        phone: "",
    }),
    computed: {
      totalCost() {
        return this.selected_price.value * this.selected_count
      }
    },
    watch: {
      selected_type: function(value) {
          this.filteredProducts = this.products.filter((product) => product.type === value.value)
          this.selected_count = 1
          if (this.filteredProducts.length) {
            this.selected_product = this.filteredProducts[0]
            if (this.selected_product.prices.length) {
              this.selected_price = this.selected_product.prices[0]
            }
          } else {
            this.selected_price = {}
            this.selected_product = {}
          }
      },
      filteredProducts: function (value) {
        this.selected_count = 1
        if (value.length){
          this.selected_product = value[0]
        } else {
          this.selected_product = {}
        }
      },
      selected_product: function (value) {
        this.selected_count = 1
        if (value.prices !== undefined && value.prices.length){
          this.selected_price = value.prices[0]
        } else {
          this.selected_price = {
            value: 0
          }
        }
      },
      // eslint-disable-next-line no-unused-vars
      selected_price: function (value) {
        this.selected_count = 1
      }
    },
    mounted() {
      const headers = {
        "Authorization": "Token f756c047576aa281b3d3ed9306f8c0e02e4a99dd",
      };
      axios
        .get('https://payment-back.kenbu-kokoro.ru/api/product', {headers})
        .then(response => {
            this.products = response.data;
            this.selected_type = this.options[0]
            this.filteredProducts = this.products.filter((product) => product.type === this.selected_type.value)
            this.selected_product = this.filteredProducts[0]
            this.selected_price = this.selected_product.prices[0]
        })
        .catch(error => {
            console.log(error);
        })
    },
    methods: {
      sendData() {
        let data = {
          product: this.selected_product.name,
          amount: this.totalCost,
          fio: this.name,
          email: this.email
        }
        let headers = {
          "Authorization": "Token f756c047576aa281b3d3ed9306f8c0e02e4a99dd",
        };
        axios
          .post('https://payment-back.kenbu-kokoro.ru/api/payment', data, {headers})
          .then(response => {
              window.location.href = response.data;
          })
          .catch(error => {
            console.log(error);
          })
      },
    }
}
</script>