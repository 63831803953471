<template>
    <v-app>
    <div class="wrapper">
        <v-footer class="footer">
            <div class="container-footer">
                <div class="footer__row">
                    <div class="footer__left">
                        <div class="left__support">
                            <div class="left__title">Техподдержка:</div>
                            <a href="mailto:kenbu-jutsu@yandex.ru" class="left__support-link" target="_blank">kenbu-jutsu@yandex.ru</a>
                        </div>
                        <div class="left__schedule">
                            <div class="left__title">Время работы:</div>
                            <div class="left_schedule-info">
                                Вторник, среда, четверг, суббота - с 10:00 до 19:00. <br>
                                Понедельник, пятница, воскресенье - выходной
                            </div>
                        </div>
                        <div class="left_paylinks">
                            <a href="#" class="left_paylink" target="_blank"><picture><source srcset="@/assets/img/visa.webp" type="image/webp"><img src="@/assets/img/visa.png" alt="VISA"></picture></a>
                            <a href="#" class="left_paylink" target="_blank"><picture><source srcset="@/assets/img/mastercard.webp" type="image/webp"><img src="@/assets/img/mastercard.png" alt="MasterCard"></picture></a>
                            <a href="#" class="left_paylink" target="_blank"><picture><source srcset="@/assets/img/mir.webp" type="image/webp"><img src="@/assets/img/mir.png" alt="MIR"></picture></a>
                            <a href="#" class="left_paylink" target="_blank"><picture><source srcset="@/assets/img/tinkoff.webp" type="image/webp"><img src="@/assets/img/tinkoff.png" alt="Tinkoff"></picture></a>
                        </div>
                        <div class="left_sociallinks">
                            <a href="https://kenbu-kokoro.ru" class="left_sociallink" target="_blank"><picture><source srcset="@/assets/img/web.webp" type="image/webp"><img src="@/assets/img/web.png" alt="kenbu-kokoro.ru"></picture><span>kenbu-kokoro.ru</span></a>
                            <a href="https://www.instagram.com/kenbu_kokoro/" class="left_sociallink" target="_blank"><picture><source srcset="@/assets/img/inst.webp" type="image/webp"><img src="@/assets/img/inst.png" alt="Instagram"></picture></a>
                        </div>
                    </div>
                    
                    <div class="footer__right">
                        <div class="right_requisites">
                            ОГРНИП 317774600144626 <br>
                            ИНН 772609763528 <br>
                            ИП Кузнецова-Гуренкова Юлия Александровна
                        </div>
                        <div class="right_links">
                            <a href="#" class="right_link" target="_blank">Политика конфиденциальности</a>
                            <a href="#" class="right_link" target="_blank">Договор-оферта</a>
                        </div>
                    </div>
                </div>
            </div>
        </v-footer>
    </div>
    </v-app>
</template>

<script>
    export default {
      name: 'Footer',
    }
</script>